import React from "react";

const AboutView = props => {
  return (
    <div className="about">
      <h1>What is Setback?</h1>
      <p>Setback is a fast-paced, match-based, non-violent, sci-fi, multiplayer footracing FPS.</p>
    </div>
  );
};

export default AboutView;
